<!-- ========== Left Sidebar Start ========== -->
<div class="left-side-menu" appOutSideClick>
  <ngx-simplebar [options]="options">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <ul id="side-menu">
          <li class="menu-title"> Painel </li>
          <li routerLinkActive="menuitem-active">
            <a routerLinkActive="active" [routerLink]="['/painel/dashboard']">
              <i class="fas fa-home size-icon"></i>
              <span> Início </span>
            </a>
          </li>
          <li routerLinkActive="menuitem-active" *appHasRole="['MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" [routerLink]="['/painel/clientes']">
              <i class="fas fa-city size-icon"></i>
              <span> Clientes </span>
            </a>
          </li>
          <li routerLinkActive="menuitem-active" *appHasRole="['ASSOCIATION', 'ENTERPRISE', 'MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" [routerLink]="['/painel/associacoes']">
              <i class="fas fa-users size-icon"></i>
              <span> OSCs </span>
            </a>
          </li>
          <li routerLinkActive="menuitem-active" *appHasRole="['ASSOCIATION', 'ENTERPRISE', 'MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" [routerLink]="['/painel/projetos']">
              <i class="fas fa-book-open size-icon"></i>
              <span> Projetos </span>
            </a>
          </li>

          <li class="menu-title mt-2"> Campanhas</li>
          <ng-container *appHasRole="['ASSOCIATION', 'ENTERPRISE', 'MODERATOR', 'ADMIN']">
            <li routerLinkActive="menuitem-active" *appHasModule="['SELECTION']">
              <a routerLinkActive="active" [routerLink]="['/painel/campanhas/selecao']">
                <i class="fas fa-project-diagram size-icon"></i>
                <span> Seleção </span>
              </a>
            </li>
          </ng-container>
          <ng-container *appHasRole="['MODERATOR', 'ADMIN']">
            <li routerLinkActive="menuitem-active">
              <a routerLinkActive="active" routerLinkActive="active" [routerLink]="['/painel/campanhas/avaliacao']">
                <i class="fas fa-tasks size-icon"></i>
                <span> Avaliação </span>
              </a>
            </li>
          </ng-container>
          <ng-container *appHasRole="['GUEST', 'ENTERPRISE', 'MODERATOR', 'ADMIN']">
            <li routerLinkActive="menuitem-active" *appHasModule="['SELECTION']">
              <a routerLinkActive="active" routerLinkActive="active" [routerLink]="['/painel/campanhas/curadoria']">
                <i class="fas fa-hand-holding-heart size-icon"></i>
                <span> Curadoria </span>
              </a>
            </li>
          </ng-container>
          <ng-container *appHasRole="['ENTERPRISE', 'MODERATOR', 'ADMIN']">
            <li *appHasModule="['MONITORING']">
              <a>
                <i class="fas fa-street-view icon-size"></i>
                <span> Monitoramento </span>
                <span class="badge badge-pink float-right" title="Em Desenvolvimento">v.2.3</span>
              </a>
            </li>
          </ng-container>
          <ng-container *appHasRole="['ENTERPRISE', 'MODERATOR', 'ADMIN']">
            <li *appHasModule="['FUND_RAISING']">
              <a>
                <i class="fas fa-donate size-icon"></i>
                <span> Captação </span>
                <span class="badge badge-pink float-right" title="Em Desenvolvimento">v.2.4</span>
              </a>
            </li>
          </ng-container>

          <li class="menu-title mt-2" *appHasRole="['MODERATOR', 'ADMIN']">Estatísticas</li>
          <li routerLinkActive="menuitem-active" *appHasRole="['MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" routerLinkActive="active" [routerLink]="['/painel/graficos']">
              <i class="fas fa-chart-bar size-icon"></i>
              <span> Gráficos </span>
            </a>
          </li>

          <li class="menu-title mt-2" *appHasRole="['MODERATOR', 'ADMIN']"> Configurações</li>
          <li routerLinkActive="menuitem-active" *appHasRole="['MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" [routerLink]="['/painel/areas']">
              <i class="fas fa-layer-group size-icon"></i>
              <span> Áreas </span>
            </a>
          </li>
          <li routerLinkActive="menuitem-active" *appHasRole="['MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" [routerLink]="['/painel/fundos']">
              <i class="fas fa-gavel size-icon"></i>
              <span> Fundos </span>
            </a>
          </li>
          <li routerLinkActive="menuitem-active" *appHasRole="['MODERATOR', 'ADMIN']">
            <a routerLinkActive="active" [routerLink]="['/painel/indicadores']">
              <i class="fas fa-balance-scale-left size-icon"></i>
              <span> Indicadores </span>
            </a>
          </li>
        </ul>
      </div>
      <!-- End Sidebar -->
      <div class="clearfix"></div>
  </ngx-simplebar>
  <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->
