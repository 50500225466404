import { Directive, OnInit, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appToggleDashboardMenu]'
})
export class ToggleDashboardMenuDirective implements OnInit {

  visible = true;
  contentPage;
  leftSideMenu;

  constructor( private renderer: Renderer2) {}

  ngOnInit() {
    this.contentPage = document.documentElement.querySelector('.content-page');
    this.leftSideMenu = document.documentElement.querySelector('.left-side-menu');
    this.onResize();
  }

  @HostListener('click') onClick(){
    if (!this.visible){
      this.show(this.contentPage, this.leftSideMenu);
    }
    else {
      this.close(this.contentPage, this.leftSideMenu);
    }
  }

  @HostListener('window:resize') onResize(){
    if (window.innerWidth < 992){
      this.close(this.contentPage, this.leftSideMenu);
    }
    else { this.show(this.contentPage, this.leftSideMenu); }
  }

  // @HostListener('document:click', ['$event.path'])
  // public clickOffLeftSide(targetElementPath: Array<any>) {
  //   if (window.innerWidth < 992){
  //     const clickOff = targetElementPath.some(element => element.className === 'content-page');
  //     if (clickOff && this.visible){
  //       this.close(this.contentPage, this.leftSideMenu);
  //     }
  //   }
  //   const clickOff = targetElementPath.some(element => element.className === 'content-page');
  // }

  show(contentPage, leftSideMenu){
    this.visible = true;
    //this.renderer.setStyle(leftSideMenu, 'transition', 'all 1000ms');
    this.renderer.setStyle(contentPage, 'margin-left', '240px');
    this.renderer.setStyle(leftSideMenu, 'display', 'block');
  }

  close(contentPage, leftSideMenu){
    this.visible = false;
    //this.renderer.setStyle(leftSideMenu, 'transition', 'all 1000ms');
    this.renderer.setStyle(contentPage, 'margin-left', '0px');
    this.renderer.setStyle(leftSideMenu, 'display', 'none');
  }

}
