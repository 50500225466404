
<div class="modal-content">
  <div class="modal-header border">
      <h4 class="modal-title font-weight-bold">Cadastro de Usuário</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="this.step === 'user'">
      <form [formGroup]="this.formUser" class="needs-validation" novalidate>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Nome: </label>
              <input type="text" class="form-control" formControlName="name" [(ngModel)]="this.user.name" placeholder="Ex: Roberto" [ngClass]="{ 'is-invalid': submittedUser && fu.name.errors }">
              <div *ngIf="submittedUser && fu.name.errors" class="invalid-feedback">
                <div *ngIf="fu.name.errors.required">Campo <b>NOME</b> é obrigatório</div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Sobrenome: </label>
              <input type="text" class="form-control" formControlName="lastName" [(ngModel)]="this.user.lastName" placeholder="Ex: da Silva" [ngClass]="{ 'is-invalid': submittedUser && fu.lastName.errors }">
              <div *ngIf="submittedUser && fu.lastName.errors" class="invalid-feedback">
                <div *ngIf="fu.lastName.errors.required">Campo <b>SOBRENOME</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>CPF: </label>
              <input type="text" class="form-control" mask="000.000.000-00" [dropSpecialCharacters]="false" formControlName="cpf" [(ngModel)]="this.user.cpf" placeholder="Ex: 999.999.999-99" [ngClass]="{ 'is-invalid': submittedUser && fu.cpf.errors }">
              <div *ngIf="submittedUser && fu.cpf.errors" class="invalid-feedback">
                <div *ngIf="fu.cpf.errors.required">Campo <b>CPF</b> é obrigatório</div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Telefone: </label>
              <input type="text" class="form-control" formControlName="phone" [(ngModel)]="this.user.phone" placeholder="Ex: (11) 95742-6666">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>E-mail: </label>
              <input type="email" class="form-control" formControlName="username" [(ngModel)]="this.user.credentials.username" placeholder="Ex: nome@domínio.com.br" [ngClass]="{ 'is-invalid': submittedUser && fu.username.errors }">
              <div *ngIf="submittedUser && fu.username.errors" class="invalid-feedback">
                <div *ngIf="fu.username.errors.required">Campo <b>E-MAIL</b> é obrigatório</div>
                <div *ngIf="fu.username.errors.email"><b>E-MAIL</b> Inválido</div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Senha: </label>
              <input type="password" class="form-control" formControlName="password" [(ngModel)]="this.user.credentials.password" placeholder="Digite uma senha segura." [ngClass]="{ 'is-invalid': submittedUser && fu.password.errors }">
              <div *ngIf="submittedUser && fu.password.errors" class="invalid-feedback">
                <div *ngIf="fu.password.errors.required">Campo <b>SENHA</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="this.step === 'address'">
      <form [formGroup]="this.formAddress" class="needs-validation" novalidate>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>CEP: </label>
              <input type="text" class="form-control" formControlName="zipCode" mask="00000-000" [dropSpecialCharacters]="false" (focusout)="this.searchCep()" [(ngModel)]="this.user.address.zipCode" placeholder="Ex: 99999-999" [ngClass]="{ 'is-invalid': submittedAddress && fa.zipCode.errors }">
              <div *ngIf="submittedAddress && fa.zipCode.errors" class="invalid-feedback">
                <div *ngIf="fa.zipCode.errors.required">Campo <b>CEP</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Logradouro: </label>
              <input type="text" class="form-control" formControlName="publicPlace" [(ngModel)]="this.user.address.publicPlace" placeholder="Preenchimento Automático" [ngClass]="{ 'is-invalid': submittedAddress && fa.publicPlace.errors }">
              <div *ngIf="submittedAddress && fa.publicPlace.errors" class="invalid-feedback">
                <div *ngIf="fa.publicPlace.errors.required">Campo <b>LOGRADOURO</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-3">
              <label>Nº: </label>
              <input type="text" class="form-control" formControlName="number" [(ngModel)]="this.user.address.number" placeholder="Ex: 5000">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-3">
              <label>Complemento: </label>
              <input type="text" class="form-control" formControlName="complement" [(ngModel)]="this.user.address.complement" placeholder="Ex: Andar 10, Sala 5">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Bairro: </label>
              <input type="text" class="form-control" formControlName="neighborhood" [(ngModel)]="this.user.address.neighborhood" placeholder="Preenchimento Automático" [ngClass]="{ 'is-invalid': submittedAddress && fa.neighborhood.errors }">
              <div *ngIf="submittedAddress && fa.neighborhood.errors" class="invalid-feedback">
                <div *ngIf="fa.neighborhood.errors.required">Campo <b>BAIRRO</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Cidade: </label>
              <input type="text" class="form-control" formControlName="city" [(ngModel)]="this.user.address.city" placeholder="Preenchimento Automático" [ngClass]="{ 'is-invalid': submittedAddress && fa.city.errors }">
              <div *ngIf="submittedAddress && fa.city.errors" class="invalid-feedback">
                <div *ngIf="fa.city.errors.required">Campo <b>CIDADE</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mb-3">
              <label>Estado: </label>
              <input type="text" class="form-control" formControlName="state" [(ngModel)]="this.user.address.state" placeholder="Preenchimento Automático" [ngClass]="{ 'is-invalid': submittedAddress && fa.state.errors }">
              <div *ngIf="submittedAddress && fa.state.errors" class="invalid-feedback">
                <div *ngIf="fa.state.errors.required">Campo <b>ESTADO</b> é obrigatório</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="acceptTerms" formControlName="acceptTerms" [(ngModel)]="this.acceptTerms">
          <label class="form-check-label" for="acceptTerms">Declaro estar de acordo com <a [href]="linkAcceptTerms" target="_blank"><strong>Termos de Uso e Compromisso</strong></a> e <a [href]="linkPrivatePolicy" target="_blank"><strong>Políticas de Privacidade</strong></a>.</label>
        </div>
      </form>
      <div *ngIf="submittedAddress && fa.acceptTerms.errors" class="invalid-feedback">
        <div *ngIf="fa.acceptTerms.errors.required">Para prosseguir é necessário que esteja de acordo com os termos acima.</div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="this.step === 'user' ? this.changeStep() : this.onSubmit()">{{ this.step === 'user' ? 'Próximo' : 'Cadastrar'}}</button>
    <button type="button" class="btn btn-danger" (click)="this.step === 'user' ? this.onClose() : this.changeStep()">{{ this.step === 'user' ? 'Cancelar' : 'Voltar'}}</button>
  </div>
</div><!-- /.modal-content -->

