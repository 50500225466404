<!-- Footer Start -->
<footer class="footer">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-6">
              <a class="text-white" href="https://www.incentivados.com.br" target="_blank">{{ currentYear | date: 'yyyy' }} &copy; Plataforma Incentivados Versão: 2.2.8</a>
          </div>
          <div class="col-md-6">
              <div class="text-md-right footer-links d-none d-sm-block">
                  <a [href]="linkAcceptTerms" target="_blank">Termos de Uso e Compromisso</a>
                  <a [href]="linkPrivatePolicy" target="_blank">Políticas de Privacidade</a>
              </div>
          </div>
      </div>
  </div>
</footer>
<!-- end Footer -->
