import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer-dashboard',
  templateUrl: './footer-dashboard.component.html',
  styleUrls: ['./footer-dashboard.component.css']
})
export class FooterDashboardComponent implements OnInit {

  currentYear: Date = new Date();
  linkAcceptTerms: string = environment.linkAccepTerms;
  linkPrivatePolicy: string = environment.linkPrivatePolicy;

  constructor() { }

  ngOnInit(): void {
  }

}
